<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card v-if="$route.params.langId">
      <div class="mb-3 w-100 d-flex align-items-center justify-content-between">
        <div>
          <h1>افزودن برگه</h1>
          <span v-if="!isNewPage && $route.query.lang"
            >مشاهده برگه در زبان
            {{ $route.query.lang == "انگلیسی" ? "فارسی" : "انگلیسی" }}:
          </span>
          <a v-if="!isNewPage" :href="mainPageLink" target="_blank">
            {{ $route.query.title }}
          </a>
        </div>
        <b-form-group label="صفحه دائمی است؟" v-slot="{ ariaDescribedby }">
          <div
            class="w-100 d-flex align-items-center justify-content-center"
            style="gap: 1rem"
          >
            <b-form-radio
              v-model="pageDetails.isDeletable"
              :aria-describedby="ariaDescribedby"
              name="isDeletable"
              :value="false"
            >
              بله
            </b-form-radio>
            <b-form-radio
              v-model="pageDetails.isDeletable"
              :aria-describedby="ariaDescribedby"
              name="isDeletable"
              :value="true"
            >
              خیر
            </b-form-radio>
          </div>
        </b-form-group>
      </div>
      <b-row>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-form-group label="عنوان برگه" label-for="title">
              <b-form-input
                id="title"
                trim
                placeholder="درباره ما یا ..."
                v-model="pageDetails.title"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-form-group label="لینک برگه (با - جدا شود)" label-for="link">
              <b-form-input
                dir="auto"
                id="link"
                trim
                placeholder="about-us"
                v-model="pageDetails.link"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="pageShapeType" label="نوع ظاهری برگه">
            <b-form-select
              id="pageShapeType"
              v-model="pageDetails.pageShapeType"
              :options="shapeOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="summary" label="خلاصه برگه">
            <b-form-textarea
              :state="summaryState"
              id="summary"
              placeholder="خلاصه برگه را وارد کنید"
              v-model="pageDetails.summary"
            ></b-form-textarea>
            <b-form-invalid-feedback>
              <div
                class="w-100 d-flex align-items-center justify-content-between"
              >
                <p>طول خلاصه برگه نباید بیشتر از 400 کاراکتر باشد.</p>
                <template
                  v-if="pageDetails.summary && pageDetails.summary.length > 0"
                >
                  {{ pageDetails.summary.length }}/400
                </template>
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="محتوای برگه" label-for="page-content">
            <quillEditor
              ref="quillEditor"
              v-model="pageDetails.content"
              :options="editorOption"
            >
              <div id="toolbar" slot="toolbar" class="ql-toolbar ql-snow">
                <quill-toolbar></quill-toolbar>
              </div>
            </quillEditor>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="my-1">
          <b-form @submit.prevent>
            <b-form-group label="کاور برگه" label-for="imageFile">
              <b-form-file
                id="imageFile"
                v-model="pageImage"
                accept="image/*"
                @input="convertImageFileToBase64"
              ></b-form-file>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="6" class="my-1">
          <div v-if="pageImage">
            <p>پیشنمایش تصویر برگه</p>
            <div class="position-relative">
              <img
                style="width: 100%; max-height: 20rem; object-fit: contain"
                :src="imagePreview"
                alt=""
              />
              <feather-icon
                icon="Trash2Icon"
                size="25"
                class="text-danger position-absolute"
                style="left: 1rem; top: 1rem; cursor: pointer"
                @click="deletePreviewImage"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label-for="is-static" label="صفحه ثابت">
            <b-form-checkbox
              id="is-static"
              v-model="pageDetails.isStatic"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          :disabled="isCreateButtonDisabled"
          variant="primary"
          @click="sendCreatePageRequest"
        >
          ایجاد برگه
        </b-button>
      </div>
    </b-card>
    <b-card v-else>
      <h1 class="mb-3">انتخاب زبان برگه</h1>
      <language-picker
        @getLanguage="setLanguage"
        :placeholder="`لطفا ابتدا زبان برگه را انتخاب کنید`"
      ></language-picker>
      <div class="w-100 mt-3 d-flex align-items-center justify-content-end">
        <b-button
          @click="setLanguageIdOnRoute"
          variant="success"
          :disabled="pageDetails.languageId ? false : true"
        >
          ثبت زبان
        </b-button>
      </div>
    </b-card>
    <library-image-picker @insertImage="insertImage"></library-image-picker>
  </b-overlay>
</template>
<script>
export default {
  title: "افزودن برگه",
  data() {
    return {
      isLoading: false,
      pageDetails: {
        isDeleted: false,
        isStatic: false,
        pageId: 0,
        title: null,
        content: null,
        mainId: "",
        link: null,
        isDeletable: true,
        summary: null,
        seourl: null,
        seoTitle: null,
        seoDescription: null,
        languageId: null,
        pageShapeType: 1,
        createDate: new Date(Date.now()).toISOString(),
        fileData: [],
      },
      pageImage: null,
      shapeOptions: [
        {
          value: null,
          text: "یک گزینه را انتخاب کنید",
        },
        {
          value: 1,
          text: "صفحه اصلی",
        },
        {
          value: 2,
          text: "بلاگ",
        },
        {
          value: 3,
          text: "تماس با ما",
        },
        {
          value: 4,
          text: "نمایندگی",
        },
        {
          value: 5,
          text: "خدمت",
        },
        {
          value: 6,
          text: "داینامیک",
        },
      ],
      editorOption: {
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              image: this.imageMethod,
            },
          },
        },
        // Some Quill options...
        theme: "snow",
      },
    };
  },
  methods: {
    setLanguageIdOnRoute() {
      this.$router.push(`/pages/pages/create/${this.pageDetails.languageId}`);
    },
    imageMethod() {
      this.$bvModal.show("library-image-picker-modal");
    },
    insertImage(imageUrl) {
      this.$refs.quillEditor.quill.insertEmbed(
        this.$refs.quillEditor.quill.getSelection().index
          ? this.$refs.quillEditor.quill.getSelection().index
          : 1,
        "image",
        imageUrl
      );
    },
    setMenu(menu) {
      this.pageDetails.menuId = menu.menuId;
    },
    setLanguage(language) {
      this.pageDetails.languageId = language.languageId;
    },
    async sendCreatePageRequest() {
      try {
        if (this.$route.params.mainId) {
          this.pageDetails.mainId = this.$route.params.mainId;
        }
        if (
          this.pageDetails.link
          // &&
          // !this.pageDetails.link.includes("/pages/")
        ) {
          this.pageDetails.link = `/${this.pageDetails.link}`;
        }
        this.pageDetails.languageId = this.$route.params.langId;
        this.isLoading = true;
        let _this = this;
        let createNewPage = new CreateNewPage(_this);
        createNewPage.setRequestParamDataObj(_this.pageDetails);
        await createNewPage.fetch(
          (response) => {
            if (response.isSuccess) {
              _this.$toast({
                component: ToastificationContent,
                position: "bottom-center",
                props: {
                  title: `عملیات موفق`,
                  icon: "CheckIcon",
                  variant: "success",
                  text: `برگه با موفقیت ایجاد شد.`,
                },
              });
              _this.pageDetails = {
                isDeleted: false,
                pageId: 0,
                title: null,
                content: null,
                link: null,
                summary: " ",
                seourl: null,
                seoTitle: null,
                seoDescription: null,
                languageId: null,
                pageShapeType: null,
                createDate: new Date(Date.now()).toISOString(),
              };
            }
          },
          (error) => {
            console.error(`callback ${error.response}`);
          }
        );
      } catch (error) {
        console.error(`catch ${error}`);
      } finally {
        this.isLoading = false;
      }
    },
    convertImageFileToBase64() {
      const reader = new FileReader();
      reader.readAsDataURL(this.pageImage);
      reader.onload = () => {
        this.pageDetails.fileData.push({
          base64: reader.result.split(",")[1],
          priority: 1,
          previousName: "",
          isSeenByMe: true,
        });
      };
    },
    deletePreviewImage() {
      this.pageImage = null;
      this.pageDetails.fileData = [];
    },
  },
  computed: {
    isCreateButtonDisabled() {
      if (!this.summaryState && !this.pageDetails.languageId) {
        return true;
      }
      return false;
    },
    summaryState() {
      if (this.pageDetails.summary) {
        if (this.pageDetails.summary.length > 400) {
          return false;
        } else if (this.pageDetails.summary.length < 400) {
          return true;
        }
      } else {
        return null;
      }
    },
    isNewPage() {
      if (this.$route.params.mainId) {
        return false;
      }
      return true;
    },
    mainPageLink() {
      if (!this.isNewPage) {
        return `https://BourseSJ.oregonserver.com${this.$route.query.link}`;
      }
    },
    imagePreview() {
      if (this.pageImage) {
        return URL.createObjectURL(this.pageImage);
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BFormRadio,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    LanguagePicker,
    quillEditor,
    MenuPicker,
    LibraryImagePicker,
    QuillToolbar,
    BFormInvalidFeedback,
  },
};
import MenuPicker from "@/views/components/utils/MenuPicker.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { CreateNewPage } from "@/libs/Api/Page";
import QuillToolbar from "@/views/components/utils/QuillToolbar.vue";
import LibraryImagePicker from "@/views/components/utils/LibraryImagePicker.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BFormRadio,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
</script>
